@import '../../../global/theme.css';

.container {
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 16px;
    border: 1px solid var(--gray300);
    cursor: pointer;

    &:hover {
        border-color: var(--blue300);
    }

    & .assetSkeleton {
        width: 100%;
        height: auto;
        border-radius: 8px;
        background-color: var(--gray100);
        margin-bottom: 10px;
    }

    & .metricsSkeleton {
        background-color: var(--gray100);
        margin-bottom: 10px;
    }

    & .osSkeleton {
        width: 24px;
        height: 24px;
        background-color: var(--gray100);
    }

    & .creativeCard {
        display: none;
        border-radius: 8px;
        pointer-events: none;
        width: 100%;
        overflow: hidden;

        & .creativeAssetContainer {
            position: relative;

            &:hover {
                & .assetHover {
                    display: flex;
                }
            }

            & .assetHover {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                color: white;
                display: none;
                justify-content: center;
                align-items: flex-end;
                font-weight: 600;
                background: linear-gradient(to top, #292C33, transparent 90px);

                & .hoverTextContainer {
                    margin: 8px;
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;

                    & .mainText {
                        word-break: break-all;
                        white-space: unset;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        &.onlyMainText {
                            -webkit-line-clamp: 3;
                        }
                    }

                    & .moreText {
                        flex-shrink: 0;
                    }
                }
            }
        }
        &.show {
            display: flex;
            flex-direction: column;
            pointer-events: auto;
        }

        & .tags {
            max-height: 115px;
            overflow-y: scroll;
            margin-top: 8px;
        }

        & .adminAdditionalCreativeInfo {
            font-size: 10px;
            margin-top: 5px;

            & .adminDataSection {
                width: calc(100% - 10px);
                margin-top: 10px;
            }
        }
    }
}
